<template>
  <div class="main-content white">
    <div class="edit-msg">
      <textarea v-model="notification" disabled></textarea>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getGroupBuyingInfo } from '@/api/group-buying';

export default {
  data() {
    return {
      notification: null,
      groupInfo: null
    };
  },
  computed: {
    ...mapGetters(['userIsLogin']),
    groupId() {
      return this.$route.params.group_id;
    },
    message() {
      return this.$route.params.message;
    }
  },
  created() {
    this.fetchGroupInfo();

    this.notification = this.message;
  },
  watch: {
    groupInfo(val) {
      this.setGroupMeta(val);
      this.setMeta();
    }
  },
  methods: {
    ...mapActions('meta', ['setGroupMeta']),
    fetchGroupInfo() {
      getGroupBuyingInfo(this.groupId, this.userIsLogin).then(data => {
        this.groupInfo = data;
      });
    }
  }
};
</script>
